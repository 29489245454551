import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom';

const RegaloPage = () => {
  const { slug } = useParams();

    useEffect(() => {
        window.parent.location.href = "/encuesta/" + slug
    }, [])
    

  return (
    <div></div>
  )
}

export default RegaloPage