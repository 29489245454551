import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as LogoBlue } from '../assets/svg/logo-forus-blue.svg';
import Footer from '../components/layout/Footer';
import Header from '../components/layout/Header';
import { useStore } from '../data/storeProvider';

const NotLoginPage = () => {
  const { logout } = useStore();
  const navigate = useNavigate()
  
  useEffect(() => {
    
    logout(() => {
      navigate('/login')
    })

  }, [])
  

  return (
    
    <div className='wrapper d-flex flex-column'>
    <Header addMenu={false} />
    <section className='flex-fill'>
      <div className="container">
        <div className="row py-5">
          <div className="col-12 text-center d-flex justify-content-center">
            <div className='text-start '>
              <LogoBlue className='img-fluid' />
              <h1 className='m-0  lh-1 mt-3 text-secondary fw-bold'>Debe logearse nuevamente</h1>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer />
  </div>
  )
}

export default NotLoginPage