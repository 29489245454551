import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { WebApi } from '../api';
import { useStore } from '../data/storeProvider';
import { Button, message, Modal } from 'antd'


const OndemandPage = () => {

  const [data, setData] = useState<any>(null)
  const { usr } = useStore();
  const { slug } = useParams();
  const navigate = useNavigate()
  const [obj, setObj] = useState({ nombre: "", iframe: "1" });

  const loadData = () => {
    WebApi.getMall(slug, usr).then((response) => {
      setData(response.Data)
    }).catch((error) => {
      if (error.response.status === 401) {
        message.error(error.request.statusText);
        navigate('/error-login')
      } else if (error.response.status)
        message.error(error.request.statusText);
      else
        console.log(error)
    });
  }

  const handleOk = () => {
    setObj({ nombre: "", iframe: "1" })
  };

  const handleCancel = () => {
    setObj({ nombre: "", iframe: "1" })
  };

  useEffect(() => {
    loadData()
    return () => setData(null)
  }, [])

  return (
    <section>
      <div className="container py-5">
        <div className="row justify-content-center">
          <div className="col-12 col-lg-10">
            <h1 className='text-primary h3 text-uppercase mb-1'>ON-DEMAND</h1>
            <p className="small text-dark">Reviva aqui, los videos de cada una de las marcas</p>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-12 col-lg-10">
            <div className="row gy-3">
              {
                !!data && data.map((tienda: any, idx: number) => {
                  return tienda.TextoHtml !== "-" && (
                    <div key={idx} className='col-12 col-md-6 col-lg-3'>
                      <div className='box-play position-relative'>
                        <img src={tienda.UrlArchivo} alt="" className='img-fluid' />
                        <div className="play-button position-absolute w-100 h-100 d-flex justify-content-center align-items-center" onClick={() => { setObj({ nombre: tienda.Nombre, iframe: tienda.TextoHtml }) }}><i className="bi bi-play-circle-fill"></i></div>
                      </div>
                    </div>
                  )
                })
              }
            </div>
            {
              obj.iframe !== "1" &&
            <Modal title={obj.nombre} visible={obj.iframe !== "1"} maskClosable={false} onOk={handleOk} onCancel={handleCancel} closable={false} centered footer={[
              <Button key="back" onClick={handleCancel}>
                Cerrar
              </Button>
            ]}>
              {obj.iframe !== "1" && <div className="ratio ratio-16x9">
                <iframe src={obj.iframe} allow="autoplay; fullscreen; picture-in-picture" title="1"></iframe>
              </div>
              }
            </Modal>
            }
          </div>
        </div>

      </div>
    </section>
  )
}

export default OndemandPage