import React, { useEffect, useState } from 'react'
import { WebApi } from '../api';
import { message } from 'antd'
import { useParams } from 'react-router-dom';
import { useStore } from '../data/storeProvider';

const TiendaPage = () => {
    const [tienda, setTienda] = useState<any>({})
    const { slug } = useParams();
    const { usr } = useStore();

    const loadTienda = () => {
        WebApi.getTienda(slug, usr).then((response) => {
            setTienda(response.Data)
        }).catch((error) => {
            console.log(error)
            if (error.request.status)
                message.error(JSON.parse(error.request.responseText).Message);
            else
                console.log(error)
        });
    }

    const logger = () => {
        WebApi.logger({ "EventoId": 1, "AcreditadoId": usr.AcreditadoId , "Visita": slug }, usr).then((response) => {
            console.log(response.Data)
        }).catch((error) => {
            if(error.request)
                message.error(JSON.parse(error.request.responseText).Message);
            else 
                console.log(error)
        });
      }

    useEffect(() => {
        logger()
        loadTienda()
        return () => setTienda({})
    }, [slug])


    return (
        <section>
            {tienda.UrlStand3d && <div dangerouslySetInnerHTML={{ __html: tienda.ScriptStand3d }}></div>}
            <div className="container">
                <div className="row py-5 justify-content-center">
                    <div className="col-6 col-md-5"><h1 className='text-primary h3'>{tienda.Nombre}</h1></div>
                    <div className="col-6 col-md-5 text-end">
                        <button onClick={() => window.history.back() } className="btn btn-primary">Volver</button>
                    </div>
                    <div className="col-12 col-md-10">
                        <div className="ratio ratio-16x9">
                            {tienda.UrlStand3d && <iframe src={tienda.UrlStand3d} allow="autoplay; fullscreen; picture-in-picture"  title="1"></iframe>}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default TiendaPage