import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { WebApi } from '../api';
import { useStore } from '../data/storeProvider';
import { message, Modal } from 'antd'
import { ReactComponent as Bajada } from '../assets/svg/bajada-mall.svg';
import { url } from 'inspector';


const MallPage = () => {
  const [mall, setMall] = useState<any>([])
  const { slug } = useParams();
  const { usr } = useStore();
  const [tiendas, setTiendas] = useState<any[]>([])
  const navigate = useNavigate()
  const [isModalVisible, setIsModalVisible] = useState(false);

  const loadMall = () => {
      WebApi.getMall(slug, usr).then((response) => {
        
        setMall(response.Data)
      }).catch((error) => {
          if (error.response.status === 401){
            message.error(error.request.statusText);
            navigate('/error-login')
          }else if (error.response.status)
            message.error(error.request.statusText);
          else
              console.log(error)
      });
  }

  const loadTiendas = () => {
    WebApi.getTiendas(usr).then((response) => {
      setTiendas(response.Data)
    }).catch((error) => {
      console.log(error)
      if (error.request.status)
        message.error(JSON.parse(error.request.responseText).Message);
      else
        console.log(error)
    });
}

  const logger = () => {
    WebApi.logger({ "EventoId": 1, "AcreditadoId": usr.AcreditadoId , "Visita": slug }, usr).then((response) => {
        
    }).catch((error) => {
        if(error.request)
            message.error(JSON.parse(error.request.responseText).Message);
        else 
            console.log(error)
    });
  }

  useEffect(() => {
    console.log("mallpage -", usr);
    logger();
    loadMall();
    loadTiendas();
    //(slug === "mall-chile") ? setIsModalVisible(true) : setIsModalVisible(false)
      return () => setMall([])
  }, [slug])


  return (
    <section>
      <div className="container">
        <div className="row py-5 justify-content-center">
          <div className="col-12 col-md-10">
            <div id="wrap-mall">
            {
              !!mall.length && window.innerWidth >= 992 && <img src={ mall[0].UrlArchivo } alt={"mall-chile"} className="img-fluid"/>
            }
            {
              !!mall.length && window.innerWidth >= 992 && JSON.parse(mall[0].TextoHtml).tiendas.map((tienda: any, idx: number) => {
                return (
                  <Link key={idx} to={"/tiendas/" + tienda.slug } id={tienda.slug} className="tienda" style={tienda.css}></Link>
                )
              })
            }
            {  !!mall.length && window.innerWidth >= 992 &&
              <div id="video" style={JSON.parse(mall[0].TextoHtml).video.css} >
                <div className="ratio ratio-16x9">
                    <iframe src={JSON.parse(mall[0].TextoHtml).video.url + "&autoplay=0&title=0&byline=0&portrait=0"} title="Vimeo" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe> 
                </div>
              </div>
            }
            { window.innerWidth > 992 && <Bajada className='w-100'/> }


            {
              !!mall.length && window.innerWidth < 992 &&
              <div>
                <h4 className='text-dark mt-3'>Seleccione tienda:</h4>
                {
                  !!tiendas.length && tiendas.map((tienda: any, idx: number) => {
                    return (
                      <Link key={idx} to={"/tiendas/" + tienda.Slug } id={tienda.Slug} className="btn btn-primary d-block my-2 ti-999" style={{backgroundImage: "url(/tiendas/" + tienda.Slug + ".png)" }} >{tienda.Nombre}</Link>
                    )
                  })
                }
              </div>
            }
            </div>
          </div>
        </div>
      </div>
      <Modal title="Ganadores" visible={isModalVisible} footer={null} onCancel={() => setIsModalVisible(false)}>
        <img src="https://files.desintegra.com/forus2022/plataforma/premio1.png" alt="" className='img-fluid'/>
      </Modal>
    </section>
  )
}

export default MallPage