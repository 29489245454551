import { useEffect } from 'react';
import { Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import './App.scss';
import Layout from './components/layout/Layout';
import { useStore } from './data/storeProvider';
import AuditorioPage from './pages/AuditorioPage';
import ContactoPage from './pages/ContactoPage';
import EncuestaPage from './pages/EncuestaPage';
import LoginPage from './pages/LoginPage';
import MallPage from './pages/MallPage';
import NotLoginPage from './pages/NotLoginPage';
import OndemandPage from './pages/OndemandPage';
import RegaloPage from './pages/RegaloPage';
import SeleccionPaisPage from './pages/SeleccionPaisPage';
import TiendaPage from './pages/TiendaPage';

function App() {

  const location = useLocation();
  const { usr } = useStore();
  
  useEffect(() => {
    window.gtag('config', "G-4MRQJ48QLY", { //"UA-48589593-1"
      'page_title': window.location.href,
      'page_location': window.location.href,
      'page_path': location.pathname
    });
    //console.log(location.pathname);
  }, [location.pathname])
  
  return (
    <Routes>
        
        
        <Route path="/" element={<Navigate to={(usr) ? "/mall/mall-chile" : "login"} replace />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/regalo/:slug" element={<RegaloPage /> } />
        <Route path="/" element={<Layout addMenu={true}/>}>
          <Route path="/mall/:slug" element={<MallPage />} />
          <Route path="/tiendas/:slug" element={<TiendaPage />} />
          <Route path="/ondemand/:slug" element={<OndemandPage />} />
          <Route path="/encuesta/:slug" element={<EncuestaPage />} />
          <Route path="/auditorio" element={<AuditorioPage />} />
          <Route path="/contacto" element={<ContactoPage />} />
        </Route>
        
        <Route path="/seleccion-pais" element={<SeleccionPaisPage />} />
        <Route path="/error-login" element={<NotLoginPage />} />
      </Routes>
  );
}

export default App;
