import axios from "axios";
import { APIWEB, EVENTO_ID } from "../constants";

const axiosInstance = axios.create({
    baseURL: APIWEB,
    headers: { 'Content-type': 'application/json; charset=utf-8', Accept: 'text/json' }
 });


 export const WebApi = {
    getDataLogin: async function(obj : any) {
        return await axiosInstance.request({
            method: "POST",
            url: 'platform/obtenerDataLogin',
            data: JSON.stringify(obj)
        }).then((response) => response.data);
    },
    login: async function(obj : any) {
        return await axiosInstance.request({
            method: "POST",
            url: 'acreditado/acreditarPorEmailRut',
            data: JSON.stringify(obj)
        }).then((response) => response.data);
    },
    getPaises: async function() {
        return await axios.get(`/paises.json`)
        .then(res => res.data)
    },
    getTiendas: async function(usr : any) {
        axiosInstance.defaults.headers.common['Authorization'] = 'Bearer ' + usr.Token;
        return await axiosInstance.request({
            method: "POST",
            url: 'EventoMarcaAsociada/listarPorCategoria',
            data: JSON.stringify("tiendas-chile")
        }).then((response) => response.data);
    },
    getTienda: async function(slug : string | undefined, usr : any) {
        axiosInstance.defaults.headers.common['Authorization'] = 'Bearer ' + usr.Token;
        return await axiosInstance.request({
            method: "POST",
            url: 'EventoMarcaAsociada/obtenerPorSlug',
            data: JSON.stringify(slug)
        }).then((response) => response.data);
    },
    getMall: async function(slug : string | undefined, usr : any) {
        axiosInstance.defaults.headers.common['Authorization'] = 'Bearer ' + usr.Token;
        return await axiosInstance.request({
            method: "POST",
            url: 'eventosalacontenido/listarPorSlug',
            data: JSON.stringify(slug)
        }).then((response) => response.data);
    },
    getBySlug: async function(slug : string | undefined, usr : any) {
        axiosInstance.defaults.headers.common['Authorization'] = 'Bearer ' + usr.Token;
        return await axiosInstance.request({
            method: "POST",
            url: 'eventosala/obtenerporslug',
            data: JSON.stringify(slug)
        }).then((response) => response.data);
    },
    getEncuesta: async function(usr : any, slug: any) {
        axiosInstance.defaults.headers.common['Authorization'] = 'Bearer ' + usr.Token;
        return await axiosInstance.request({
            method: "GET",
            url: 'eventoencuesta/obtenerporslug/' + slug,
        }).then((response) => response.data);
    },
    guardarEncuesta: async function(obj : any, usr : any) {
        axiosInstance.defaults.headers.common['Authorization'] = 'Bearer ' + usr.Token;
        return await axiosInstance.request({
            method: "POST",
            url: 'eventoencuesta/guardarFront',
            data: JSON.stringify(obj)
        }).then((response) => response.data);
    },
    logger: async function(values: any, usr : any) {
        axiosInstance.defaults.headers.common['Authorization'] = 'Bearer ' + usr.Token;
        return await axiosInstance.request({
            method: "POST",
            url: 'eventoclick/registrar',
            data: JSON.stringify(values)
        }).then((response) => response.data);
    },
 }

 //verificaYaRespondida