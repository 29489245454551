import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { WebApi } from '../api';
import { ReactComponent as LogoBlue } from '../assets/svg/logo-forus-blue.svg';
import { useStore } from '../data/storeProvider';
import { message } from 'antd';
import HeaderLogin from '../components/layout/HeaderLogin';
import Footer from '../components/layout/Footer';
import LoginBox from '../components/loginbox/LoginBox';

const LoginPage = () => {
  const [data, setData] = useState(null)
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate()
  const { usr, login } = useStore()

  const loadData = () => {
    let token = searchParams.get("token")
      WebApi.getDataLogin({ 'Token': token }).then((response) => {
        if(!response.Exito) { navigate("/error-login"); return false; }
        login(response.Data, () => { navigate("/seleccion-pais") })
      }).catch((error) => {
        if (error.request.status)
          message.error(JSON.parse(error.request.responseText).Message);
        else if(error.code === "ERR_NETWORK")
          message.error(error.message);
        else
          console.log(error)
      });
  }

  useEffect(() => {
    console.log(usr)
    if(usr !== null) navigate('/mall/mall-chile')
    return () => setData(null)
  }, [usr])


  return (
    <div className='wrapper d-flex flex-column bg-loginweb'>
        <HeaderLogin />
        <main className='flex-fill'>
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-12 col-lg-4 pt-5">
                  <LoginBox/>
                </div>
              </div>
            </div>
        </main>
        <Footer/>
    </div>
  )
}

export default LoginPage