
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { APIWEB } from "../constants";


type Props = {
    children?: React.ReactNode
};

const StoreContext = React.createContext<any>(undefined);

export const ProvideStore: React.FC<Props> = ({ children }) => {
    const store = useProvideStore();
    return (
        <StoreContext.Provider value={store}>
            {children}
        </StoreContext.Provider>
    );
}

const useProvideStore = () => {
    const [usr, setUsr] = useState<any>(null);
    const [tiendas, setTiendas] = useState<any[]>([]);
    const location = useLocation()
    const [estado, setEstado] = useState('LOADING');

    const login = (usr: any, cb?: () => void) => {
        setUsr(usr)
        localStorage.setItem("LoggedUser",JSON.stringify(usr));
        console.log("logeado desde localstorage",usr)
        setEstado('CARGADO');
        if(cb) cb();
    };

    const logout = (cb: () => void) => {
        setUsr(null);
        localStorage.removeItem("LoggedUser");
        cb();
    };

    const getUserData = async () => {
        const response = await Promise.all([ localStorage.getItem("LoggedUser") ]);
        const responseData = await response[0];
        if(responseData === null || responseData === "null"){
            setUsr(null);
            setEstado('CARGADO');
        }else{
            const data = await JSON.parse(responseData);
            login(data,()=>{  });
        }
    }

    const getTiendas = async (slug : string) => {
        console.log('slug',slug)
        const response = await Promise.all([ axios.post( APIWEB + 'eventosalacontenido/listarPorSlug', JSON.stringify(slug),{ headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + usr.Token } }) ]);
        const responseData = await response[0].data;
        var arr = responseData.Data;
        console.log(arr);
        setTiendas(arr);
    }


    useEffect(() => {
        getUserData();
    }, []);

    return {
        usr,
        login,
        logout,
        tiendas,
        setTiendas,
        estado
    };
}

export const useStore: any = () => {
    return useContext(StoreContext);
};

