import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { WebApi } from '../api';
import { useStore } from '../data/storeProvider';
import { message } from 'antd'


const ContactoPage = () => {
    const [data, setData] = useState<any>(null)
    const { usr } = useStore();
    const navigate = useNavigate()

    const loadData = () => {
        WebApi.getBySlug("contacto", usr).then((response) => {
          setData(response.Data)
        }).catch((error) => {
            if (error.response.status === 401){
              message.error(error.request.statusText);
              navigate('/error-login')
            }else if (error.response.status)
              message.error(error.request.statusText);
            else
                console.log(error)
        });
    }


    const logger = () => {
      WebApi.logger({ "EventoId": 1, "AcreditadoId": usr.AcreditadoId , "Visita": "contacto" }, usr).then((response) => {
          console.log(response.Data)
      }).catch((error) => {
          if(error.request)
              message.error(JSON.parse(error.request.responseText).Message);
          else 
              console.log(error)
      });
    }

    useEffect(() => {
      logger()
        loadData()
          return () => setData(null)
      }, [])

  return (
    <section>
        <div className="container py-5">
            <div className="row justify-content-center">
                <div className="col-10">
                    <h1 className='text-primary h3 text-uppercase'>Contacto</h1>
                    { !!data && <div dangerouslySetInnerHTML={{ __html: data.Descripcion }}></div> }
                </div>
            </div>
        </div>
    </section>
  )
}

export default ContactoPage