import React from 'react'

const Footer = () => {
  return (
    <footer className='bg-secondary p-1 text-center'>
      <p className='m-0 text-white'>FORUS, todos los derechos reservados.</p>
    </footer>
  )
}

export default Footer