import React, { useEffect, useState } from 'react'
import { WebApi } from '../api';
import { ReactComponent as LogoBlue } from '../assets/svg/logo-forus-blue.svg';
import { useStore } from '../data/storeProvider';
import { message } from 'antd'
import { Link } from 'react-router-dom';
import Footer from '../components/layout/Footer';
import Header from '../components/layout/Header';
import { ReactComponent as LogoMall } from '../assets/svg/logo-mall-color.svg';


const SeleccionPaisPage = () => {
  const [obj, setObj] = useState<{ loading: boolean, error: string, data: any }>({ loading: false, error: "", data: null })
  const { usr, login, estado } = useStore()


  const loadData = () => {
    WebApi.getPaises().then((response) => {
      setObj({ ...obj, loading: false, data: response })
    }).catch((error) => {
      if (error.request.status)
        message.error(JSON.parse(error.request.responseText).Message);
      else
        console.log(error)
    });
  }

  const logger = () => {
    WebApi.logger({ "EventoId": 1, "AcreditadoId": usr.AcreditadoId , "Visita": "seleccion-pais" }, usr).then((response) => {
        console.log(response.Data)
    }).catch((error) => {
        if(error.request)
            message.error(JSON.parse(error.request.responseText).Message);
        else 
            console.log(error)
    });
  }

  useEffect(() => {
    logger()
    loadData();
    return () => setObj({ loading: false, error: "", data: null })
  }, [])


  return (
    <div className='wrapper d-flex flex-column'>
      <Header addMenu={false} />
      <section className='flex-fill'>
        <div className="container">
          <div className="row py-2 py-lg-5">
            <div className="col-12 text-center d-flex justify-content-center">
              <div className='text-start '>
                <LogoBlue className='img-fluid' />
                <h1 className='m-0  lh-1 mt-3 text-secondary fw-bold'>OTOÑO <br />INVIERNO 2022</h1>
                <p className='mb-0'>LANZAMIENTO COLECCIÓN</p>
              </div>
            </div>
            <div className="col-12 mt-3 text-center">
              <p><strong>Bienvenidos a la colección otoño invierno</strong>, selecciona tu país</p>
            </div>
            <div className="col-12 mt-3 text-center d-flex flex-column align-items-center justify-content-center">
              <div className="wrap-paises py-3">
                <div className="row justify-content-around">
                  {
                    !!obj.data && obj.data.paises.map((pais: any, idx: number) => {
                      return (
                        <div key={idx} className="col-6 col-md-2 col-lg-2 px-4 px-md-2 mb-3 mb-md-0">
                          <Link to={"/mall/" + pais.slug} onClick={() => { usr.pais = pais; login(usr) }}>
                            <img src={pais.urlImagen} alt={pais.nombre} className="img-fluid"/>
                            <p className='m-0 mt-2 h6 fw-light text-gris'>{pais.nombre}</p>
                          </Link>
                        </div>
                      )
                    })
                  }
                </div>
              </div>
              <LogoMall className='mt-5'/>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  )
}

export default SeleccionPaisPage