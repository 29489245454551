import { Button, Form, Input, message } from "antd";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { WebApi } from "../../api";
import { EVENTO_ID } from "../../constants";
import { useStore } from "../../data/storeProvider";


const tailLayout = {
    wrapperCol: { span: 24 },
};
const { validarRUT } = require('validar-rut')

const emailRegex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

const allCharactersSame = (s: string) => {
    let n = s.length;
    for (let i = 1; i < n; i++)
        if (s[i] !== s[0])
            return false;

    return true;
}

const LoginBox = () => {

    const [loading, setLoading] = useState(false)
    const [validating, setValidating] = useState(false)
    const [ form ] = Form.useForm()
    const { usr, login } = useStore()
    const navigate = useNavigate()

    const onFinish = (values: any) => {
        setLoading(true);
        values.Apellidos = "";
        values.NumeroDocId = (values.NumeroDocId) ? values.NumeroDocId.replaceAll('.', '').replaceAll('-', '').replace(/^0+/, '') : "";
        values.Telefono = "";
        values.Direccion = "";
        values.Region = "";
        values.Profesion = "";
        values.ProgramasAsiste = [];
        values.EventoId = EVENTO_ID;
        values.PerteneceEmpresaSocia = "NO";
        values.EmpresaCasaEstudio = "";
        values.AreaInteres = "";
        values.EventoProgramaId = 0;
        values.ComoSeEntero = "";
        values.TerminosCondiciones = "";
        values.AutorizaEnvioInformacion = "";
        values.FechaHora = new Date().toISOString();
        values.PreAcreditado = "NO";
        values.FechaPreAcreditado = new Date().toISOString();
        values.TipoDocId = "RUT"
        console.log(values);
        

        WebApi.login(values).then((response) => {
            if(response.Exito){
                login(response.Data, () => { navigate("/mall/mall-chile") })
            }else{
                message.error(response.Mensaje)
            }
            setLoading(false);
        }).catch((error) => {
        if (error.request.status)
          message.error(JSON.parse(error.request.responseText).Message);
        else if(error.code === "ERR_NETWORK")
          message.error(error.message);
        else
          console.log(error)
          setLoading(false);
      });
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };





    

    return (
        <div className="">
            <h2 className="text-primary text-center mb-0 fw-bold">¡BIENVENIDOS!</h2>
            <p className="text-primary text-center mb-0">INGRESA TUS DATOS AQUÍ:</p>
            <Form
                layout="vertical"
                name="basic"
                form={form}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                size="large"
            >
                <p className="text-primary text-center mb-0 mt-4">NOMBRES</p>
                <Form.Item
                    name="Nombres"
                    wrapperCol={{ span: 24 }}
                    rules={[{ required: true, message: 'Por favor ingrese su Nombre!' }]}
                >
                    <Input className="text-center"/>
                </Form.Item>
                <p className="text-primary text-center mb-0">EMAIL</p>
                <Form.Item
                    name="Email"
                    wrapperCol={{ span: 24 }}
                    rules={[ { required: true, message: 'Por favor ingrese su email!', type: 'email' }]}
                >
                    <Input className="text-center"/>
                </Form.Item>
                <p className="text-primary text-center mb-0">RUT</p>
                <Form.Item
                    name="NumeroDocId"
                    validateStatus={(validating) ? "validating" : ""}
                    wrapperCol={{ span: 24 }}
                    
                    rules={[
                        {
                            required: true,
                            validator: (_, value) => {
                                if(validarRUT(value) && !allCharactersSame(value)) {
                                    return Promise.resolve()
                                }else if(emailRegex.test(value)){
                                    return Promise.resolve()
                                }else{ 
                                    setValidating(false);
                                    return Promise.reject(new Error('Rut incorrecto')) 
                                }
                            }
                        },
                    ]}
                >
                    <Input className="text-center"/>
                </Form.Item>

                <Form.Item {...tailLayout} style={{ textAlign: 'center' }}>
                    <Button className="btn btn-primary bg-primary text-white" htmlType="submit" loading={loading}>ENTRAR</Button>
                </Form.Item>
            </Form>
        </div>
    )
}
export default LoginBox