import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { WebApi } from '../api';
import { useStore } from '../data/storeProvider';
import { Button, Form, message, Radio } from 'antd'


const EncuestaPage = () => {
    const [data, setData] = useState<any>(null)
    const [loading, setLoading] = useState(false)
    const [enviada, setEnviada] = useState(false)
    const { usr } = useStore();
    const { slug } = useParams();
    const navigate = useNavigate()

    const loadData = () => {
        WebApi.getEncuesta(usr,slug).then((response) => {
          setData(response.Data)
        }).catch((error) => {
            if (error.response.status === 401){
              message.error(error.request.statusText);
              navigate('/error-login')
            }else if (error.response.status)
              message.error(error.request.statusText);
            else
                console.log(error)
        });
    }

    const onFinish = (values: any) => {
      setLoading(true)
      let respuestas : any[] = [];
      Object.keys(values).forEach(key => {
        console.log(values);
        respuestas.push({ "Puntaje": parseInt(values[key].split("|")[1]) ,"EventoEncuestaId":data.EventoEncuestaId, "AcreditadoId": usr.AcreditadoId, "Pregunta": key, "Respuesta": values[key].split("|")[0], "Comentario": ""})
      });

      WebApi.guardarEncuesta(respuestas, usr).then((response) => {
        console.log(response)
        setLoading(false)
        setEnviada(true)
      }).catch((error) => {
          if (error.response.status === 401){
            message.error(error.request.statusText);
            navigate('/error-login')
          }else if (error.response.status)
            message.error(error.request.statusText);
          else
              console.log(error)
      });
    };
  
    const onFinishFailed = (errorInfo: any) => {
      console.log('Failed:', errorInfo);
    };

    const logger = () => {
      WebApi.logger({ "EventoId": 1, "AcreditadoId": usr.AcreditadoId , "Visita": "encuesta" }, usr).then((response) => {
          console.log(response.Data)
      }).catch((error) => {
          if(error.request)
              message.error(JSON.parse(error.request.responseText).Message);
          else 
              console.log(error)
      });
    }

    useEffect(() => {
        logger()
        loadData()
          return () => setData(null)
      }, [])

  return (
    <section className='py-5'>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-md-10">
            <h1 className='text-primary h3'>PREMIO ESCONDIDO</h1>
          </div>
          <div className="col-12 col-md-10">
            <div className="bg-white border-primary p-3">
            {
              enviada ? 
              <div className='text-center'>
                <h4 className='text-primary fw-bold'>Gracias!</h4>
                <p>Tus respuestas fueron almacenadas correctamente</p>
              </div>
              :
              <Form
                layout="vertical"
                name="basic"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
              >
                {
                  data !== null && data.MisPreguntas.map((p: any, idx: number) => {
                    return (
                      <Form.Item rules={[{ required: true, message: 'Seleccione una respuesta' }]} key={idx} name={ p.Pregunta } label={<div className='h6 fw-bold' dangerouslySetInnerHTML={{ __html: p.Pregunta }}></div>}>
                        <Radio.Group>
                          {
                            p.MisRespuestas.map((r: any, index: number) => {
                              return (
                                <Radio value={r.Respuesta + '|' + r.Puntaje} key={idx + "-" + index} className="d-block mb-1">{ r.Respuesta }</Radio>
                              )
                            })
                          }
                        </Radio.Group>
                      </Form.Item>
                    )
                  })
                }
                {
                  data !== null && 
                  <div className='text-end'>
                    <button className='btn btn-primary' type='submit' disabled={loading}>Enviar</button>
                  </div>
                }
                
              </Form>
            }
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default EncuestaPage