import React, { useEffect, useState } from 'react'
import { WebApi } from '../api';
import { useStore } from '../data/storeProvider';
import { message } from 'antd'
import { useNavigate } from 'react-router-dom';
import BgAuditorio from '../assets/images/auditorio.jpeg'


const AuditorioPage = () => {
    const [data, setData] = useState<any>(null)
    const { usr } = useStore();
    const navigate = useNavigate()
    const [active, setActive] = useState(false)

    const loadData = () => {
        WebApi.getBySlug("auditorio-chile", usr).then((response) => {
            setData(response.Data)
            console.log(response.Data)
        }).catch((error) => {
            if (error.response.status === 401) {
                message.error(error.request.statusText);
                navigate('/error-login')
            } else if (error.response.status)
                message.error(error.request.statusText);
            else
                console.log(error)
        });
    }


    const logger = () => {
        WebApi.logger({ "EventoId": 1, "AcreditadoId": usr.AcreditadoId, "Visita": "auditorio" }, usr).then((response) => {
            console.log(response.Data)
        }).catch((error) => {
            if (error.request)
                message.error(JSON.parse(error.request.responseText).Message);
            else
                console.log(error)
        });
    }

    useEffect(() => {
        logger()
        loadData()
        return () => setData(null)
    }, [])

    return (
        <section>
            <div className="container">
                <div className="row py-5 justify-content-center">
                    <div className="col-12 pe-md-0">
                        <div className='position-relative'>
                            <img src={BgAuditorio} alt="" className='img-fluid d-none d-lg-block' />
                            <div id="video-auditorio">
                                <div className="ratio ratio-16x9">
                                    {data !== null && <iframe src={data.UrlStraming} allow="autoplay; fullscreen; picture-in-picture" title="1"></iframe>}
                                </div>
                            </div>
                            {data !== null && usr &&
                                <div className={ (active) ? "d-flex flex-column active" : "d-flex flex-column" } id="wrap-chat">
                                    <div className="pageHome__iframeTitle bg-primary text-white padding text-center py-2">CHAT INTERACTIVO <a href="#" onClick={ () => setActive(!active) } className='btn btn-secondary text-white position-absolute rounded-0 d-none d-lg-block' id="btn-chatdown" style={{ right:0, top: 0 }}><i className="bi bi-caret-down-fill"></i> <i className="bi bi-caret-up-fill"></i></a> </div>
                                    <iframe src={`/backend/chat/chatgw.aspx?salaid=${data.EventoSalaId}&user=${usr.Email}`} allowTransparency className="iframe-chat flex-grow-1"></iframe>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default AuditorioPage