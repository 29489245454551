import React from 'react';
import { Outlet } from "react-router-dom";
import { useStore } from '../../data/storeProvider';
import Footer from './Footer';
import Header from './Header';


interface OwnProps {
    addMenu: boolean
}

const Layout : React.FC<OwnProps> = ({ addMenu }) => {
    const { usr, estado } = useStore()

    if (estado === 'LOADING') {
        return <div>Cargando...</div>;
    }
    
    return (
        <div className='wrapper d-flex flex-column'>
            <Header addMenu={addMenu} />
            <main className='flex-fill'>
                <Outlet/>
            </main>
            <Footer/>
        </div>
    )
}

export default Layout
