import React, { useEffect, useState } from 'react'
import { ReactComponent as HeaderLeft } from '../../assets/svg/header-left.svg';
import { ReactComponent as LogoWhite } from '../../assets/svg/logo-white.svg';
import { ReactComponent as LeftMenu } from '../../assets/svg/left-menu.svg';
import { ReactComponent as Puntos } from '../../assets/svg/puntos.svg';
import { Link, NavLink } from 'react-router-dom';
import { useStore } from '../../data/storeProvider';
import { WebApi } from '../../api';
import {Drawer, message } from 'antd'
import Submenu from './Submenu';
import { ReactComponent as LogoMall } from '../../assets/svg/logo-mall.svg';


interface OwnProps {
  addMenu: boolean
}


const Header: React.FC<OwnProps> = ({ addMenu }) => {
  const [paises, setPaises] = useState<any[]>([])
  const { usr, login } = useStore()
  const [visible, setVisible] = useState(false);

  const toggleMenu = () => {
    setVisible(!visible);
  };
  const onClose = () => {
    setVisible(!visible);
  };

  const loadPaises = () => {
    WebApi.getPaises().then((response) => {
      console.log(response.paises)
      setPaises(response.paises)
    }).catch((error) => {
      if (error.request.status)
        message.error(JSON.parse(error.request.responseText).Message);
      else
        console.log(error)
    });
  }

  useEffect(() => {
    if(addMenu) loadPaises()
    return () => setPaises([])
  }, [])
  
  console.log('addMenu', usr)

  return (
    <header>
        <div className="wrapTop bg-login">
          <div className="container">
            <div className="row">
              <div className="col-12 d-flex">
                <div className="wrap-logo flex-fill d-flex align-items-center">
                    <div className='d-flex align-items-center align-items-lg-end justify-content-between w-100'>
                        <LogoWhite className='ms-2 ms-lg-5 logo-forus' />
                        <h2 className='text-secondary m-0 fw-bold lh-1 d-none d-lg-block'>PRIMAVERA <br />VERANO 2022</h2>
                        <p className='text-white m-0 d-none d-lg-block'>LANZAMIENTO COLECCIÓN</p>
                        <LogoMall className='me-1 logo-mall'/>
                        <button className='btn-menu d-lg-none' onClick={toggleMenu}><i className="bi bi-list"></i></button>
                    </div>
                </div>
                <Puntos className='mt-3 d-none d-lg-block me-5'/>
              </div>
            </div>
          </div>
        </div>
      {
        addMenu && usr !== null && 
        <Drawer placement="right" onClose={onClose} visible={visible}>
          <nav id="menu-mobile" className='d-flex alifn-items-center'>
            <ul className='m-0 p-0 d-flex flex-column'>
              <li><NavLink to="/mall/mall-chile" className={({ isActive }) => isActive ? 'text-primary' : undefined } onClick={toggleMenu}>Inicio</NavLink></li>
              <li className='sub-menu'><a>Tiendas</a>
                  {
                    addMenu && <Submenu toggle={toggleMenu}/>
                  }
              </li>
              <li><NavLink to="/auditorio" onClick={toggleMenu} hidden={true}>Auditorio</NavLink></li>
              <li><NavLink to="/ondemand/ondemand-chile" onClick={toggleMenu}>On-Demand</NavLink></li>
              <li><NavLink to="/tiendas/gerencia-personas-chile" onClick={toggleMenu}>Gerencia</NavLink></li>
              <li><NavLink to="/contacto" onClick={toggleMenu}>Contacto</NavLink></li>
              
            </ul>
          </nav>
        </Drawer>
      }
      {
        addMenu && usr !== null && 
        <div className="wrap-menu d-none d-lg-block mt-2">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="d-flex">
                  <LeftMenu />
                  <div className='flex-fill bg-secondary position-relative d-flex justify-content-between'>
                    <nav id="menu" className='d-flex alifn-items-center'>
                      <ul className='m-0 p-0 d-flex'>
                        <li><NavLink to="/mall/mall-chile" className={({ isActive }) => isActive ? 'text-primary' : undefined }>Inicio</NavLink></li>
                        <li className='sub-menu'><a href="#">Tiendas</a>
                            {
                              addMenu && <Submenu/>
                            }
                        </li>
                        <li><NavLink to="/auditorio" hidden={true}>Auditorio</NavLink></li>
                        <li><NavLink to="/ondemand/ondemand-chile">On-Demand</NavLink></li>
                        <li><NavLink to="/tiendas/gerencia-personas-chile">Gerencia</NavLink></li>
                        <li><NavLink to="/contacto">Contacto</NavLink></li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </header>
  )
}

export default Header