import React from 'react';
import { ReactComponent as Barra } from '../../assets/svg/header-login.svg';
import { ReactComponent as LogoMall } from '../../assets/svg/logo-mall.svg';
import { ReactComponent as LogoWhite } from '../../assets/svg/logo-white.svg';
import { ReactComponent as Puntos } from '../../assets/svg/puntos.svg';

const HeaderLogin: React.FC = () => {


  return (
    <header>
        <div className="wrapTop bg-login">
          <div className="container">
            <div className="row">
              <div className="col-12 d-flex">
                <div className="wrap-logo flex-fill d-flex align-items-center">
                    <div className='d-flex align-items-center align-items-lg-end justify-content-between w-100'>
                        <LogoWhite className='ms-2 ms-lg-5 logo-forus' />
                        <h2 className='text-secondary m-0 fw-bold lh-1 d-none d-lg-block'>PRIMAVERA <br />VERANO 2022</h2>
                        <p className='text-white m-0 d-none d-lg-block'>LANZAMIENTO COLECCIÓN</p>
                        <LogoMall className='me-1 logo-mall'/>
                    </div>
                </div>
                
                <Puntos className='mt-3 d-none d-lg-block me-5'/>
              </div>
            </div>
          </div>
        </div>
        <div className='pt-3 d-none d-lg-block'>
            <Barra className='img-fluid'/>
        </div>
    </header>
  )
}

export default HeaderLogin