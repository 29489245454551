import { message } from 'antd';
import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { WebApi } from '../../api';
import { useStore } from '../../data/storeProvider';


interface OwnProps {
  toggle?: () => void;
}

const Submenu: React.FC<OwnProps> = ({ toggle }) => {
    const [tiendas, setTiendas] = useState<any[]>([])
    const { usr } = useStore()

    const loadTiendas = () => {
        WebApi.getTiendas(usr).then((response) => {
          setTiendas(response.Data)
        }).catch((error) => {
          console.log(error)
          if (error.request.status)
            message.error(JSON.parse(error.request.responseText).Message);
          else
            console.log(error)
        });
    }

    useEffect(() => {
      loadTiendas()
      return () => setTiendas([])
    }, [])
    

    return (
        <ul>
            {
                !!tiendas.length && tiendas.map((tienda: any, idx: number) => {
                    return (
                        <li key={idx}><NavLink to={"/tiendas/" + tienda.Slug} className={({ isActive }) => isActive ? 'text-primary' : undefined } onClick={toggle}>{tienda.Nombre}</NavLink></li>
                    )
                })
            }
        </ul>
    )
}

export default Submenu